import { Button, SvgIcon, Typography } from "@mui/material";
import PMALineBullet from "../components/PMALineBullet";
import { Box, Stack } from "@mui/system";
import { List, ListItem } from '@mui/material';
import MembershipTables from "../components/MembershipTables";
import { alignProperty } from "@mui/material/styles/cssUtils";
import PMAPageFooter from "../components/PMAPageFooter";
import {blue, red} from '@mui/material/colors';
import { Image } from "@mui/icons-material";
import PMAMahaPayLogo from '../resources/PMAMahaBnkQRCode.png';

function MembershipPg() {

    const secTitle = "";

    return(
    <div>
        <br/>
        <Box sx={{mr: 5, ml:5}}>
            <PMALineBullet secTitle={secTitle + 'Memberships'}/>
            <br/>
            <Typography variant="h6">
                PMA primarily works on the basis of <b>Joy of Giving Back to The Society</b>. However, Being part of Association with
                legacy of stalwarts in the field, some of the benefits that members get from PMA Membership are as follows

                <List>
                    <ListItem sx={{ml:5,listStyleType: "disc", 
                        display: "list-item"}}>
                        Joy of giving Back To The society, Next Generation and budding Managers
                    </ListItem>

                    <ListItem sx={{ml:5,listStyleType: "disc", 
                        display: "list-item"}}>
                        Opportunity to widen knowledge Opportunity to update latest in <b>Multidisciplinary </b>Management field and management practices
                    </ListItem>

                    <ListItem sx={{ml:5,listStyleType: "disc", 
                        display: "list-item"}}>
                        Get informed of our activities Each month by Mail with some useful thoughts on management practices thru our various Social Media platform
                    </ListItem>

                    <ListItem sx={{ml:5,listStyleType: "disc", 
                        display: "list-item"}}>
                        The members will be given concession in the participation fees for nominating themselves (participants for Institutional Members) to the training programmes conventions, seminars, etc.
                    </ListItem>

                    <ListItem sx={{ml:5,listStyleType: "disc", 
                        display: "list-item"}}>
                        PMA organizes training programmes regularly Priority will be given to members in for registration of participants for such programmes
                    </ListItem>

                    <ListItem sx={{ml:5,listStyleType: "disc", 
                        display: "list-item"}}>
                        Regular monthly Free and Open lectures on current management topics
                    </ListItem>

                    <ListItem sx={{ml:5,listStyleType: "disc", 
                        display: "list-item"}}>
                        Networking Opportunities with Seniors and Experts in various fields
                    </ListItem>

                </List>
                
            </Typography>
            <br/>

            <PMALineBullet secTitle={secTitle + 'Professional Memberships ^'}/>
            <br/>
            <MembershipTables tblType={1} />
            <Typography variant="body" fontSize={10} sx={{color:'secondary.main'}}>
                <b>^Members can also pay Membership Fees for 5 (five) years together - Rs. 4500</b>
            </Typography>
            <br/>
            <br/>
            <PMALineBullet secTitle={secTitle + 'Students Memberships'}/>
            <br/>
            <MembershipTables tblType={0} />
            <br/>
            <br/>
            <PMALineBullet secTitle={secTitle + 'Payment Options'}/>
            <br/>
            <Typography variant="h6">
            Membership period is from April to March (Financial Year). However, if Member joins in between 
            the financial year, his/her subscription is adjusted in the next payment cycle. 
            (Example If someone becomes Member - in say August 23,  
            in the next subscription cycle starting - April 24 he/she has to pay only 
            Rs 350 to align his/her membership period to the financial year.
            <p/>
            {/* <b>Please send email to training@punemanagementassociation.org for Institutional Membership Form and also after making payment </b>
             */}
            <p>Making payment for PMA Membership / Paid Programs is very easy. We have FOUR options for making Payment</p>

            <p><b>Option 1</b> Internet Banking/IMPS/NEFT. Details – A/c name : Pune Management Association, Savings a/c no 20076322476, Bank of Maharashtra, Shivajinagar. IFSC-MAHB0000043 </p>
            <p>OR <b>Option 2 </b>Through Bank draft / Cheques drawn in Favour of PUNE MANAGEMENT ASSOCIATION. You can deposit it in any of BoM Branches. </p>
            
            <p> 
                OR <b>Option 3 </b> Scan and Pay Pay through your UPI/BHIM/GPay app using following QR Code: 
        
            
            
            <Box
                component="img"
                sx={{
                    //height: 350,
                    //width: 350,
                    maxHeight: { xs: 100, md: 150 },
                    maxWidth: { xs: 100, md: 150 },
                    ml: 5
                }}
                alt="PMA Maha QR Code"
                src={PMAMahaPayLogo}
                >
            </Box>
            </p>

        {/* <SvgIcon component={PMAMahaPayLogo} inheritViewBox /> */}

        <p> OR <b>Option 4</b> Just Click the link below and fill in your details.    
            
            {/* <Button sx={{ml: 2}} variant="contained" href="https://www.instamojo.com/@punemanagement/"><b>Online - Pay Here ==&gt;</b></Button> */}
            {/* <Button sx={{ml: 2}} variant="contained" href="https://dashboard.razorpay.com/app/dashboard"><b>Online - Pay Here ==&gt;</b></Button> */}
            <Button sx={{ml: 2}} variant="contained" 
            href="https://razorpay.me/@punemanagementassociation"
            target="_blank"><b>Online - Pay Here </b></Button>
            &nbsp; (charges apply)
            </p>

            {/* <p> OR <b>Alt 5</b> You may mail/ request for copy of QR Code for making payment.
                Alternatively, Just 
                <a href="https://www.punemanagement.org.in/payment"><b> click here</b></a>
                , you will get various alternatives.

            </p> */}
            <p>
                <b>REQUEST</b> &gt;&gt; Please eMail &gt;&gt; Screen shot with your Full Name, eMail ID, Mobile no to PuneManagementAssociation@gmail.com after making the payment.
            </p>
            <p><b>
                Please send an email to training@PuneManagementAssociation.org, in case you require Institutional/Corporate Membership Form     
            </b></p>
            <p>
                <b>Members/Non Members-Well-wishers are invited to to join our (Free) Groups -</b>
                <ul>
                    <li> <a href="https://tinyurl.com/ywc4n9t8">Facebook</a></li>
                    <li><a href="https://tinyurl.com/48e3chwx">Telegram</a></li>
                    {/* <li><a href="tinyurl.com/mstes6mz">eGroups</a></li> */}
                    <li><a href="https://tinyurl.com/fu8h3sx7">LinkedIn</a></li>
                    <li><a href="https://tinyurl.com/hsm7r43e">YouTube</a></li>
                    <li><a href="https://tinyurl.com/2p87ru44">WA</a></li>
                </ul>
            </p>
            </Typography>
        </Box>

        <PMAPageFooter />

        </div>
    );
    
}

export default MembershipPg;